import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CompBlockCols from "../../components/MainStats/CompBlockCols"
import CompetitionBlock from "../../components/MainStats/CompetitionBlock"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import SubPageMenu from '../../components/Utilities/SubPageMenu'
// import CopyBlock from "../../components/Copy/CopyBlock"
// import InfoBar from '../../components/Utilities/InfoBar'

class MessiInterMiamiStats extends React.Component {

  render() {

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    return (

      <Layout>
        <SEO
          title={"Lionel Messi Inter Miami Goals and Stats"}
          description={`Lionel Messi goals, assists, hat-tricks, free kicks and other stats for Inter Miami in MLS, the MLS Cup Playoffs and all other competitions.`}
          canonicalPath={`/all-time-stats/messi-inter-miami-stats/`}
        />

        <h1>
          Messi Inter Miami Goals and Stats
        </h1>

        

        <SubPageMenu
          pages={[
            { 'name': 'Inter Miami Stats', 'url': 'none', 'active': true },
            { 'name': 'PSG Stats', 'url': '/all-time-stats/messi-psg-stats/' },
            { 'name': 'Barcelona Stats', 'url': '/all-time-stats/messi-barcelona-stats/' },
          ]}
        />

        <CompBlockCols>

          <CompetitionBlock competition="All Time Inter Miami" team="Inter Miami" mdata={mdata} rdata={rdata} perfmsg="[comps] only" />

          <CompetitionBlock competition="All Time MLS" compTitle="MLS" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time MLS Cup" compTitle="MLS Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time CONCACAF Champions Cup" compTitle="CONCACAF Champions Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time US Open Cup" compTitle="US Open Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time Leagues Cup" compTitle="Leagues Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="All Time Campeones Cup" compTitle="Campeones Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          {/* <CompetitionBlock competition="UEFA Super Cup - Inter Miami" compTitle="UEFA Super Cup" team="Inter Miami" mdata={mdata} rdata={rdata} />

          <CompetitionBlock competition="Club World Cup - Inter Miami" compTitle="Club World Cup" team="Inter Miami" mdata={mdata} rdata={rdata} /> */}

        </CompBlockCols>

        
        {/* <CopyBlock>
          <h2>Messi & Ronaldo in Games vs Each Other</h2>

          <InfoBar />

          

          <p>If you’re looking for more Messi vs Ronaldo stats, follow us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, where we consistenly post exclusive stats.</p>

        </CopyBlock> */}

        <LastUpdated type="Stats" />
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/intermiami/"}}) {
      edges {
        node {
          id
          competition
        }
      }
    }
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/intermiami/"}}) {
      edges {
        node {
          id
          competition
          goals
          assists
          apps
          minsPerGoal
          minsPerGoalContribution
          goalsPerGame
          minsPlayed
          pens
          pensMissed
          hatTricks
          freeKicks
          insideBox
          outsideBox
          left
          right
          head
          other
          shots
          shotsOnTarget
          keyPasses
          successfulDribbles
          throughballs
          aerialDuels
          motm
          avgRating
          shotsPerGoal
          freeKickAttempts
          bigChancesCreated
          xg
          fkc
          xgc
        }
      }
    }
  }
`

export default MessiInterMiamiStats
